<template>
    <div class="blog">
        blog
    </div>
</template>

<script>
    /*eslint-disable*/
    import firebase from 'firebase/app';

    export default {
        name: "Blog",
        data() {
           return {
               allUsers: []
           }
        }
    }
</script>
