import App from './App.vue'
import Vue from 'vue'
import router from "./Router/router";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {
    Badge,
    Button,
    Dialog,
    ButtonGroup,
    Col,
    Input,
    InputNumber,
    Loading,
    Menu,
    MenuItem,
    Message,
    MessageBox,
    Notification,
    Option,
    Popover,
    Radio,
    RadioButton,
    RadioGroup,
    Row,
    Select,
    Submenu,
    Tooltip,
} from 'element-ui';


Vue.use(Menu);
Vue.use(Dialog);
Vue.use(Badge);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Tooltip);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Row);
Vue.use(Col);
Vue.use(Popover);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    created() {
        firebase.initializeApp({
            apiKey: "AIzaSyCY07ncRIuQkW5T0TnBZDLFFt6FqOJkDKI",
            authDomain: "portfolio-eed2f.firebaseapp.com",
            databaseURL: "https://portfolio-eed2f.firebaseio.com",
            projectId: "portfolio-eed2f",
            storageBucket: "portfolio-eed2f.appspot.com",
            messagingSenderId: "880117475989",
            appId: "1:880117475989:web:778911011a418a1fa1499c",
            measurementId: "G-JT4WGLX1C4"
        });
    }
}).$mount('#app')

