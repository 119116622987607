import Vue from 'vue'
import Router from "vue-router";

import Home from '.././components/Home'
import Login from '.././components/Login'
import Blog from '.././components/Blog'
import Widget from '.././components/shortcode-widget-lite'


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "Home",
      props: { home: true},
      component: Home
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/blog",
      name: "Blog",
      component: Blog
    },
    {
      path: "/shortcode-widget-lite",
      name: "Widget",
      component: Widget
    }
  ]
});
