<template>
    <div class="shortcode-widget-main-wrapper">
        <div class="shortcode-widget-banner">
            <!--banner image here-->
            <div class="shortcode-widget-banner-head">
                <h1>WordPress Plugin</h1>
                <h1>Shortcode Widget Lite</h1>
            </div>
        </div>
        <div class="shortcode-widget-content">
            <h1>Shortcode Widget Lite</h1>
            <p class="post-time"><i class="el-icon-time"/> 17 Nov Sunday At 8.35 pm </p>
            <hr class="post-margin-bottom">
            <p>A WordPress plugin to use the shortcode on widgets.</p>
            <p>Sometimes we have to show some of our content on the
                sidebar or footer section of our WP site which comes from the shortcode. But It's not very easy to
                render a shortcode on the sidebar.
                <br>
                Like if you have a form plugin that provides shortcode to render the
                form. How can you do it? <br> Don't worry this will help you to do that.
            </p>


            <h2> Installation:</h2>
            <ul class="post-lists">
                <li>Just download it from <a
                        href="https://github.com/hasanuzzamanbe/shortcode-widget-lite/archive/master.zip">Here</a> and
                    upload it on your WordPress plugin.
                </li>
                <img class="post-image" src="../assets/docs/upload.png"/>
                <li>Activate your plugin.</li>
                <img class="post-image" src="../assets/docs/activate.png">
            </ul>
            <p class="post-padding-all">Your setup is complete. Now you can use it like your other WordPress widgets.
                Let's see how easy is that.</p>
            <h2 class="post-margin-top"> How to use?</h2>

            <ul class="post-lists">
                <li>Then go to Dashboard >> appearance >> widgets</li>
                <p>It will show a new widget "shortcode-widget-lite"</p>
                <img class="post-image" src="../assets/shortcode-widget-lite.png"/>
            </ul>
            <p class="post-margin-top">
                The title is optional, If you don't want to show the title then keep it empty.
                Now see the output of the shortcode on the frontend.
                Hope this plugin will help you. If you face any issue please let me know.
            </p>
            <div class="post-fruitnote post-margin-bottom">
                <p><i style="margin-right:10px; color: #c0c5c7;" class="el-icon-question"/>Any question or sugestion ?
                    please
                    <span @click="openChat()" style="color:green;font-weight:bold;"> Text me</span></p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'widget',
        data() {
            return {}
        },
        methods: {
            openChat() {
                this.$emit('openChatbox');
            }
        }
    }
</script>
<style scoped>
    @import '../scss/shortcode-widget.scss';
</style>
