<template>
    <div>
        <article class="image-container">
            <div
                :style="{backgroundImage: 'url(' + project.image + ')'}"
                class="post-preview-thumbnail"
            ></div>
            <div class="post-preview-content">
                <h1>{{project.name}}</h1>
            </div>
            <div class="controller-area">
                <el-button-group class="project-controller">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="Quick View"
                        placement="top-start"
                    >
                        <el-button
                            type="primary"
                            v-if="project.quickView"
                            @click="project.showFrame = !project.showFrame"
                            size="small"
                        >
                            <i class="el-icon-view"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="View Full page"
                        placement="top-start"
                    >
                        <a
                            class="el-button el-button--info el-button--mini"
                            target="_blank"
                            :href="project.link"
                        >
                            <i class="el-icon-s-platform"></i>
                        </a>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="Source Code on github"
                        placement="top-start"
                    >
                        <a
                            class="el-button el-button--warning el-button--mini"
                            target="_blank"
                            :href="project.source"
                        >
                            <i class="el-icon-folder-opened"></i>
                        </a>
                    </el-tooltip>
                </el-button-group>
            </div>
        </article>

        <el-dialog :title="project.name" :visible.sync="project.showFrame" height="100%">
            <iframe :src="project.url" width="100%" height="100%" frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>
<script>
/*eslint-disable*/
export default {
    name: "singleproject",
    props: ["project"]
};
</script>
<style scoped>
    @import '../scss/single-project.scss';
</style>
