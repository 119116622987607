<template>
    <div class="homepage">
        <div class="header-content">
            <div class="header-content-inner">
                <div class="avatar-image-xaaman-left-col">
                    <span>Hello I'm</span>
                    <h2>Hasanuzzaman</h2>
                    <h4>Tech Lead at AuthLab</h4>
                    <ul>
                        <li>
                            <i class="el-icon-message"></i>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Click to send mail"
                                placement="top-start"
                            >
                                <a
                                    href="mailto:hasanuzzamanbe@gmail.com"
                                    style="text-decoration: none; color:#c0c5c7;
                                        cursor: pointer;"
                                >hasanuzzamanbe@gmail.com</a>
                            </el-tooltip>
                        </li>
                        <li> Former Principle Software Engineer at AuthLab</li>
                        <li> Former Lead Software Engineer at AuthLab</li>
                        <li> Former Senior Software Engineer at WPManageNinja</li>
                        <li>
                            <i class="el-icon-phone"></i>+8801749813408
                        </li>
                        <li>
                            <i class="el-icon-location"></i>Dhaka, Bangladesh
                        </li>
                    </ul>

                    <div class="link">
                        <a
                            href="http://www.facebook.com/shamim0902"
                            aria-label="fb"
                            target="_blank"
                        >
                            <img src="../assets/facebook.png" alt="fb" />
                        </a>
                        <a href="http://www.twitter.com/shamim0902" aria-label="Tw" target="_blank">
                            <img src="../assets/twitter.png" alt="tw" />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/shamim0902/"
                            aria-label="ln"
                            target="_blank"
                        >
                            <img src="../assets/linkedin.png" alt="ln" />
                        </a>
                        <a
                            href="http://www.github.com/hasanuzzamanbe"
                            aria-label="gt"
                            target="_blank"
                        >
                            <img src="../assets/github.png" alt="gt" />
                        </a>
                        <a
                            href="https://profiles.wordpress.org/hasanuzzamanshamim"
                            aria-label="wp"
                            target="_blank"
                        >
                            <img src="../assets/wordpress.png" alt="gt" />
                        </a>
                    </div>
                </div>
                <div class="avatar-image-xaaman-right-col" :span="12">
                    <div class="avatar-image-xaaman"></div>
                </div>
            </div>
            <div class="bottom-dark-svg"></div>
        </div>
        <div class="about-me-container">
            <div class="about-me-container-inner">
                <div class="about-me-container-left">
                    <img
                        src="../assets/projects/about-me.png"
                        style="max-width: 553px; width:100%;"
                        alt="about me"
                    />
                </div>
                <div id="textme" class="about-me-container-right" style="max-width: 418px;">
                    <h1>About Me</h1>
                    <p>
                        Hello, <br/>
                        I am Hasanuzzaman a full-stack software developer.
                        I have rich experience in frontend programming.
                        Also I am good at...
                    </p>

                    <ul style="margin-left: -40px;">
                        <li v-for="(skill,i) in skills" :key="i">
                            <span>{{skill}}</span>
                        </li>
                    </ul>
                    <img
                        id="peekaboPandaImg2"
                        :class="{peekabooHideText: isHoveringText, peekabooSeekText: !isHoveringText}"
                        src="../assets/peekaboo.png"
                        alt="pkb"
                        @click="openChat"
                    />
                    <button
                        id="textMe-Button"
                        @mouseover="isHoveringText = false"
                        @mouseleave="isHoveringText = true"
                        @click="openChat"
                    >Text Me</button>
                </div>
            </div>
        </div>
        <div class="follow-me-container">
            <div class="follow-me-container-inner">
                <div class="follow-me-container-left">
                    <h1>Github</h1>
                    <div>
                        <div class="github-profile">
                            <h2 v-if="githubData.name">{{githubData.name}}</h2>
                            <h2 v-else>Hasanuzzaman</h2>
                            <h4 v-if="githubData.company">{{githubData.company}}</h4>
                            <h4 v-else>Authlab</h4>
                            <p
                                v-if="githubData.location"
                                style="margin-top:-15px;"
                            >{{githubData.location}}</p>
                            <p v-else>Sylhet, Bangladesh</p>
                            <ul>
                                <el-tooltip content="Public repository" placement="top">
                                    <li
                                        v-if="githubData.public_repos"
                                        class="public_repos"
                                    >{{githubData.public_repos}}</li>
                                    <li v-else class="public_repos">44</li>
                                </el-tooltip>
                                <el-tooltip content="Public Gists" placement="top">
                                    <li
                                        v-if="githubData.public_gists"
                                        class="public_gists"
                                    >{{githubData.public_gists}}</li>
                                    <li v-else class="public_gists">3</li>
                                </el-tooltip>
                                <el-tooltip content="Followers" placement="top">
                                    <li
                                        v-if="githubData.followers"
                                        class="public_followers"
                                    >{{githubData.followers}}</li>
                                    <li v-else class="public_followers">15</li>
                                </el-tooltip>
                            </ul>
                        </div>
                        <img
                            id="peekaboPandaImg"
                            :class="{peekabooHide: isHovering, peekabooSeek: !isHovering}"
                            src="../assets/peekaboo.png"
                            alt="pkb"
                            @click="goToGit"
                        />
                        <button
                            @mouseover="isHovering = false"
                            @mouseout="isHovering = true"
                            target="_blank"
                            @click="goToGit"
                            class="follow-me-button"
                        >Follow Me on Github</button>
                    </div>
                </div>
                <div class="follow-me-container-right">
                    <img src="../assets/projects/github.png" alt="about me" />
                </div>
            </div>
        </div>
        <div class="portfolio-container">
            <!-- <div class="top-svg"></div> -->
            <div class="portfolio-container-inner">
                <div id="portfolio">
                    <h1 style="text-align: center;">Projects</h1>
                </div>
                <div style="text-align:center; margin-bottom: 50px;">
                    <p style="color: #c2c22c;">Please visit <a target="_blank" href="https://www.github.com/hasanuzzamanbe">github</a> for recent work. Also you may read / write blogs at <a target="_blank" href="https://wpminers.com">WP Miners</a></p>
                </div>
                <div v-if="false" id="myBtnContainer" style="display: flex; justify-content: center;">
                    <button
                        class="btn"
                        @click="filterSelection('all')"
                        :style="{borderBottom: selectedProject === 'all' ? '2px solid #e6a23c': null }"
                    >All</button>
                    <button
                        class="btn"
                        @click="filterSelection('node-js')"
                        :style="{borderBottom: selectedProject === 'node-js' ? '2px solid #e6a23c': null }"
                    >Node</button>
                    <button
                        class="btn"
                        @click="filterSelection('vue-js')"
                        :style="{borderBottom: selectedProject === 'vue-js' ? '2px solid #e6a23c': null }"
                    >Vue</button>
                    <button
                        class="btn"
                        @click="filterSelection('brow-ext')"
                        :style="{borderBottom: selectedProject === 'brow-ext' ? '2px solid #e6a23c': null }"
                    >Browser</button>
                    <button
                        class="btn"
                        @click="filterSelection('wp-plugin')"
                        :style="{borderBottom: selectedProject === 'wp-plugin' ? '2px solid #e6a23c': null }"
                    >WP Plugin</button>
                    <button
                        class="btn"
                        @click="filterSelection('jquery')"
                        :style="{borderBottom: selectedProject === 'jquery' ? '2px solid #e6a23c': null }"
                    >jQuery</button>
                </div>
<!--                <div class="project-content">-->
<!--                    <div v-for="(project,i) in filterProjects" :key="i">-->
<!--                        <div class="single-project">-->
<!--                            <singleproject :project="project" />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        <div class="bird-container bird-container--one">
            <div class="bird bird--one"></div>
        </div>

        <div class="bird-container bird-container--two">
            <div class="bird bird--two"></div>
        </div>
        <div id="peekaboo">
            <span class="project-reminder">
                Do you have any project idea? Please share with me
                <a
                    style="text-decoration: none;
                      cursor: pointer;
                      color: #188c91;"
                    target="_blank"
                    href="http://www.github.com/hasanuzzamanbe"
                >😊</a>
            </span>
            <img src="../assets/micky.png" alt />
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
function yScrollHandler() {
    var win = document.getElementById("peekaboo");
    if (win) {
        if (
            window.pageYOffset + window.innerHeight >=
            document.body.offsetHeight
        ) {
            win.style.webkitTransition = "right 0.7s ease-in-out 0s";
            win.style.transition = "right 0.7s ease-in-out 0s";
            win.style.right = "0px";
        } else {
            win.style.transition = "right 0.7s ease-in-out 0s";
            win.style.right = "-640px";
        }
    }
}

window.onscroll = yScrollHandler;
/*eslint-disable*/
import singleproject from "./SingleProject.vue";
import allProjects from "./allProjects";
export default {
    name: "Home",
    data() {
        return {
            projects: allProjects,
            filterProjects: allProjects,
            skills: [
                "JavaScript",
                "Node Js",
                "Vue js",
                "React Js",
                "jQuery",
                "php",
                "WordPress",
                "html",
                "css",
                "Browser Extension"
            ],
            selectedProject: "all",
            githubData: {},
            isHovering: true,
            isHoveringText: true,
            githubProjects: []
        };
    },
    components: {
        singleproject
    },
    methods: {
        filterSelection(val) {
            this.selectedProject = val;
            if (val == "all") {
                return (this.filterProjects = this.projects);
            } else {
                this.filterProjects = this.projects.filter(
                    item =>
                        !val ||
                        item.type.toLowerCase().includes(val.toLowerCase())
                );
            }
        },
        goToGit() {
            window.open("https://github.com/hasanuzzamanbe", "_blank");
        },
        fetchGit() {
            fetch("https://api.github.com/users/hasanuzzamanbe").then(
                response => {
                    response.json().then(data => {
                        this.githubData = data;
                    });
                }
            );
        },
        fetchAll() {
            fetch("https://api.github.com/users/hasanuzzamanbe/repos?type=public&direction=desc").then(
                response => {
                    response.json().then(data => {
                        console.log(data)
                        this.githubProjects = data;
                    });
                }
            );
        },
        openChat() {
            $crisp.push(["do", "chat:open"]);
        }
    },
    mounted() {
        this.fetchGit();
        this.fetchAll();
    }
};
</script>
<style lang="scss" scoped>
    @import '../scss/home.scss';
</style>
