export default [{
        "name": "WP bolierplate plugin with Vue.js",
        "image": require("../assets/projects/wp-vue.jpg"),
        "url": "https://hasanuzzamanbe.github.io/wp-boilerplate-plugin-with-vuejs/",
        "link": "https://hasanuzzamanbe.github.io/wp-boilerplate-plugin-with-vuejs/",
        "source": "https://github.com/hasanuzzamanbe/wp-boilerplate-plugin-with-vuejs",
        "quickView": true,
        "showFrame": false,
        "type": "wp-plugin"
    },
    {
        "name": "Telegram chatbot with WordPress plugin notifier",
        "image": require("../assets/projects/telegram-bot.png"),
        "url": "https://t.me/wpninja_bot",
        "link": "https://t.me/wpninja_bot",
        "source": "https://github.com/hasanuzzamanbe/wpninja-bot",
        "quickView": false,
        "showFrame": false,
        "type": "node-js"
    },
    {
        "name": "Vue-cli And PHP boilerplate",
        "image": require("../assets/projects/vue-php.jpg"),
        "url": "https://github.com/hasanuzzamanbe/vue-cli-php-boilerplate",
        "link": "https://github.com/hasanuzzamanbe/vue-cli-php-boilerplate",
        "source": "https://github.com/hasanuzzamanbe/vue-cli-php-boilerplate",
        "quickView": false,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "More WordPress Contributions",
        "image": require("../assets/projects/other-contributions.png"),
        "url": "https://profiles.wordpress.org/wpmanageninja/#content-plugins",
        "link": "https://profiles.wordpress.org/wpmanageninja/#content-plugins",
        "source": "https://profiles.wordpress.org/wpmanageninja/#content-plugins",
        "quickView": false,
        "showFrame": false,
        "type": "wp-plugin"
    },
    {
        "name": "Shortcode widget lite",
        "image": require("../assets/projects/widget.jpg"),
        "url": "/shortcode-widget-lite",
        "link": "/shortcode-widget-lite",
        "source": "https://github.com/hasanuzzamanbe/shortcode-widget-lite",
        "quickView": false,
        "showFrame": false,
        "type": "wp-plugin"
    },
    {
        "name": "gravatar-image",
        "image": require("../assets/projects/avatar_image.png"),
        "url": "https://www.npmjs.com/package/avatar-image",
        "link": "https://www.npmjs.com/package/avatar-image",
        "source": "https://github.com/hasanuzzamanbe/table",
        "quickView": false,
        "showFrame": false,
        "type": "node-js"
    },
    {
        "name": "Social launcher",
        "image": require("../assets/projects/launcher.jpg"),
        "url": "https://hasanuzzamanbe.github.io/social-launcher-extension/",
        "link": "https://chrome.google.com/webstore/detail/social-launcher/ioijhmpjoplcmckgaiggcoockjijmcim",
        "source": "https://github.com/hasanuzzamanbe/social-launcher-extension",
        "quickView": true,
        "showFrame": false,
        "type": "brow-ext"
    },
    {
        "name": "Mozilla Firefox Extension",
        "image": require("../assets/projects/mozilla-launcher.jpg"),
        "url": "https://hasanuzzamanbe.github.io/social-launcher-mozilla/",
        "link": "https://hasanuzzamanbe.github.io/social-launcher-mozilla/",
        "source": "https://github.com/hasanuzzamanbe/social-launcher-mozilla",
        "quickView": true,
        "showFrame": false,
        "type": "brow-ext"
    },
    {
        "name": "Author Bio Ultimate",
        "image": require("../assets/projects/author.jpg"),
        "url": "https://wordpress.org/plugins/author-bio-ultimate/",
        "link": "https://wordpress.org/plugins/author-bio-ultimate/",
        "source": "https://github.com/hasanuzzamanbe/author-bio-ultimate",
        "quickView": false,
        "showFrame": false,
        "type": "wp-plugin"
    },
    {
        "name": "Covid-19 Status",
        "image": require("../assets/projects/covidstatus.png"),
        "url": "https://covidmaps.netlify.app/",
        "link": "https://covidmaps.netlify.app/",
        "source": "https://github.com/hasanuzzamanbe/covid-19-tracker",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "iso-ccl",
        "image": require("../assets/projects/iso_ccl.png"),
        "url": "https://www.npmjs.com/package/iso-ccl",
        "link": "https://www.npmjs.com/package/iso-ccl",
        "source": "https://github.com/hasanuzzamanbe/iso-ccl",
        "quickView": false,
        "showFrame": false,
        "type": "node-js"
    },
    {
        "name": "Quick Search Lite",
        "image": require("../assets/projects/search.jpg"),
        "url": "https://hasanuzzamanbe.github.io/quick-search-extension/",
        "link": "https://chrome.google.com/webstore/detail/quick-search-lite/boikedohmpklkpcmpkickjfnmalfbimf",
        "source": "https://github.com/hasanuzzamanbe/quick-search-extension",
        "quickView": true,
        "showFrame": false,
        "type": "brow-ext"
    },
    {
        "name": "MeetUp",
        "image": require("../assets/projects/meetup_logo.jpg"),
        "url": "https://meetup01.netlify.app",
        "link": "https://meetup01.netlify.app",
        "source": "https://github.com/hasanuzzamanbe/meetup",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "Mortgage Calculator",
        "image": require("../assets/projects/mortgage.jpg"),
        "url": "https://mortgage-calculator-vue.netlify.app/",
        "link": "https://mortgage-calculator-vue.netlify.app/",
        "source": "https://github.com/hasanuzzamanbe/Mortgage-Calculator",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "Counseling Book",
        "image": require("../assets/projects/counselling.jpg"),
        "url": "https://counseling.netlify.app",
        "link": "https://counseling.netlify.app",
        "source": "https://github.com/hasanuzzamanbe/counselingBook",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "A Test Table",
        "image": require("../assets/projects/vue_table.jpg"),
        "url": "https://vue-table.netlify.app",
        "link": "https://vue-table.netlify.app",
        "source": "https://github.com/hasanuzzamanbe/table",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "Savings Calculator",
        "image": require("../assets/projects/savings.jpg"),
        "url": "https://savings-calculator-vue.netlify.app/",
        "link": "https://savings-calculator-vue.netlify.app/",
        "source": "https://github.com/hasanuzzamanbe/Savings-Calculator",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
    {
        "name": "JQuery Stepper",
        "image": require("../assets/projects/jquery_step.jpg"),
        "url": "https://jquery-stepper.netlify.app/#step-1",
        "link": "https://jquery-stepper.netlify.app/#step-1",
        "source": "https://github.com/hasanuzzamanbe/Stepwizard-With-Validation",
        "quickView": true,
        "showFrame": false,
        "type": "jquery"
    },
    {
        "name": "Tic Tac Toe Game",
        "image": require("../assets/projects/tictactoe.png"),
        "url": "https://tic-tac-toe-vuejs.netlify.app/",
        "link": "https://tic-tac-toe-vuejs.netlify.app/",
        "source": "https://github.com/hasanuzzamanbe/tic-tac-toe",
        "quickView": true,
        "showFrame": false,
        "type": "vue-js"
    },
]