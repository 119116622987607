<template>
    <div class="login-page">
        <div class="log-form">
            <form class="form">
                <input
                    type="text"
                    title="usermail"
                    v-model="usermail"
                    autocomplete="usermail"
                    placeholder="usermail"
                />
                <input
                    type="password"
                    title="username"
                    autocomplete="password"
                    v-model="userpassword"
                    placeholder="password"
                />
                <el-button class="btn" type="primary" @click.prevent="signIn()">Login</el-button>
            </form>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import firebase from "firebase/app";

export default {
    name: "Login",
    data() {
        return {
            usermail: "",
            userpassword: ""
        };
    },
    methods: {
        signIn() {
            firebase
                .auth()
                .signInWithEmailAndPassword(this.usermail, this.userpassword)
                .then(res => {
                    this.$message({
                        message: "Hello ! Hasanuzzaman",
                        type: "success"
                    });
                    this.$router.push({ path: "/admin" });
                })
                .catch(error => {
                    this.$message({
                        message: error.message,
                        type: "warning"
                    });
                });
        }
    }
};
</script>
<style scoped>
    @import '../scss/login.scss';
</style>
